/**
 * Seo component that queries for data with
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { StaticQuery, graphql } from "gatsby"
import favicon from "../img/favicon-32x32.ico"
import defaultMetaImage from "../../static/img/ctaBanner.jpg"

function Seo({
  children,
  description,
  lang,
  keywords,
  title,
  metaImage,
  isBlogPost,
  location,
  schema
}) {
  return (
    <StaticQuery
      query={graphql`
        query {
          site {
            siteMetadata {
              title
              description
            }
          }
        }
      `}
      render={({ site }) => {
        const metaDescription = description || site.siteMetadata.description
        const image = metaImage
          ? metaImage
          : "https://ratelock.ca" + defaultMetaImage
        const url = location?.href ?? "https://ratelock.ca"
        return (
          <Helmet
            htmlAttributes={{
              lang
            }}
            title={
              title
                ? title
                : "Mortgage Rates in BC-Best Mortgage Brokers in Canada-RateLock.ca"
            }
            titleTemplate={`%s | ${site.siteMetadata.title}`}
          >
            {/* General tags */}
            <link rel="canonical" href="https://ratelock.ca" />
            <link rel="icon" href={favicon} />
            <title>{title}</title>
            <meta name="description" content={description} />
            <meta name="image" content={image} />
            <meta name="keywords" content={keywords.join(`, `)} />
            {children}

            {/* OpenGraph tags */}
            <meta property="og:url" content={url} />
            <meta
              property="og:type"
              content={isBlogPost ? "article" : "website"}
            />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={image} />
            <meta property="fb:app_id" content={850882465456139} />
            {/* Twitter tags */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:image" content={image} />
            <meta name="twitter:image:alt" content={title} />
            <meta name="twitter:site" content="@ratelockca" />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={metaDescription} />

            {/* Schema */}
            {schema && (
              <script type="application/ld+json">
                {JSON.stringify(schema)}
              </script>
            )}
          </Helmet>
        )
      }}
    />
  )
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [
    "ratelock",
    "ratelock mortgage broker",
    "mortgage broker",
    "Mortgage Brokers",
    "mortgage refinance",
    "home equity loan",
    "mortgage agent",
    "best mortgage broker",
    "mortgage brokers Canada",
    "Mortgage Brokers in Canada",
    "finance broker",
    "online mortgage broker",
    "mortgage calculator",
    "mortgage advisors",
    "loans for emergency needs",
    "loans for higher education",
    "loans for retired individuals",
    "loans for tax debt",
    "mortgage loan broker",
    "debt consolidation",
    "business loans"
  ],
  description: `RateLock mortgage brokers offers business loans; loans for higher education; loans for emergency needs, retired individuals & more. Apply for home equity loan & get approved!`
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
  metaImage: PropTypes.string,
  isBlogPost: PropTypes.bool
}

export default Seo
